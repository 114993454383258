<template>
  <div>
    <div class="flex flex-column" id="helpCard">
      <content-card-full>
        <template #title>
          <card-title>
            <template #title>
              <h2>Need Help?</h2>
            </template>
          </card-title>
        </template>

        <template #content-full>
          <div>
            <Carousel :value="cards" :numVisible="3" :numScroll="1" :responsiveOptions="responsiveOptions">
              <template #item="slotProps">
                <div class="flex align-items-center justify-content-center flex-column help-inner-card">
                  <!-- if(number % 2==0){ -->
                  <div class="img-wrapper flex mb-5 justify-content-center align-items-center" :class="{ 'light': (slotProps.index % 2 !== 0) }">
                      <img class="card-img" :src="slotProps.data.image"/>
                  </div>
                  <div class="flex align-items-center">
                    <h3 class="center clarity-margin-bottom-2xs">{{ slotProps.data.title }}</h3>
                  </div>
                  <div class="flex flex-column justify-content-between align-items-center">
                    <div class="flex flex-grow-1 clarity-margin-bottom-xs help-inner-card-content">
                      <span class="body-text">{{ slotProps.data.blurb }}</span>
                    </div>
                  </div>
                  <div class="flex">
                      <Button @click="openPage(slotProps.data.buttonLink)" :label="slotProps.data.buttonText" class="clarity-gradient-orange clarity-btn"/>
                  </div>
                </div>
              </template>
            </Carousel>
          </div>
        </template>
      </content-card-full>
    </div>

    <Modal v-if="showSecureMessageTour" id="sm-welcome-tour">
      <template #body>
        <content-card-full>
          <template #title>
            <div class="flex justify-content-between title align-items-center">
              <h2>Secure Message Video Tour</h2>
              <Button class="clarity-close-modal-btn" @click="closeModal"><span
                  class="material-icons-round grey">close</span></Button>
            </div>
          </template>
          <template #content-full>
            <div>
              <div style='padding:56.25% 0 0 0;position:relative;'>
                <iframe src='https://vimeo.com/showcase/10564932/embed' allowfullscreen frameborder='0' style='position:absolute;top:0;left:0;width:100%;height:100%;'></iframe>
              </div>
            </div>
          </template>
        </content-card-full>
      </template>
    </Modal>

    <Modal v-if="showResearchNotesGuide" id="sm-welcome-tour">
      <template #body>
        <content-card-full>
          <template #title>
            <div class="flex justify-content-between title align-items-center">
              <h2>Research Tour</h2>
              <Button class="clarity-close-modal-btn" @click="closeModal"><span
                  class="material-icons-round grey">close</span></Button>
            </div>
          </template>
          <template #content-full>
            <div>
              <div style='padding:56.25% 0 0 0;position:relative;'>
                <iframe src='https://vimeo.com/showcase/10654056/embed' allowfullscreen frameborder='0' style='position:absolute;top:0;left:0;width:100%;height:100%;'></iframe>
              </div>
            </div>
          </template>
        </content-card-full>
      </template>
    </Modal>

    <Modal v-if="showValuationTour" id="sm-welcome-tour">
      <template #body>
        <content-card-full>
          <template #title>
            <div class="flex justify-content-between title align-items-center">
              <h2>ONLINE VALUATION TOUR</h2>
              <Button class="clarity-close-modal-btn" @click="closeModal"><span
                  class="material-icons-round grey">close</span></Button>
            </div>
          </template>
          <template #content-full>
            <div>
              <div style='padding:56.25% 0 0 0;position:relative;'>
                <iframe src='https://vimeo.com/showcase/10752575/embed' allowfullscreen frameborder='0' style='position:absolute;top:0;left:0;width:100%;height:100%;'></iframe>
              </div>
            </div>
          </template>
        </content-card-full>
      </template>
    </Modal>

    <Modal v-if="showNewHoldingVideo" id="sm-welcome-tour">
        <template #body>
            <content-card-full>
                <template #title>
                    <div class="flex justify-content-between title align-items-center">
                        <h2>ADD NEW HOLDING TOUR</h2>
                        <Button class="clarity-close-modal-btn" @click="closeModal"><span
                                class="material-icons-round grey">close</span></Button>
                    </div>
                </template>
                <template #content-full>
                    <div>
                        <div style='padding:56.25% 0 0 0;position:relative;'>
                            <iframe src='https://vimeo.com/showcase/10823845/embed' allowfullscreen frameborder='0' style='position:absolute;top:0;left:0;width:100%;height:100%;'></iframe>
                        </div>
                    </div>
                </template>
            </content-card-full>
        </template>
    </Modal>

    <Modal v-if="showIMSTour" id="sm-welcome-tour">
        <template #body>
            <content-card-full>
                <template #title>
                    <div class="flex justify-content-between title align-items-center">
                        <h2>IMS HOME TOUR</h2>
                        <Button class="clarity-close-modal-btn" @click="closeModal"><span
                                class="material-icons-round grey">close</span></Button>
                    </div>
                </template>
                <template #content-full>
                    <div>
                        <div style='padding:56.25% 0 0 0;position:relative;'>
                            <iframe src='https://player.vimeo.com/video/878609017?badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479' allowfullscreen frameborder='0' style='position:absolute;top:0;left:0;width:100%;height:100%;'></iframe>
                        </div>
                    </div>
                </template>
            </content-card-full>
        </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/common/Modal';
import ContentCardFull from "../common/ContentCardFull";
import CardTitle from "../common/CardTitle";
import {ref, computed} from "vue";
import {useRouter} from "vue-router";
import {useStore} from "vuex";

export default {
  name: "DashboardHelp",
  components: {CardTitle, ContentCardFull,Modal},
  props: {
    mode: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();

    const showSecureMessageTour = ref(false);
    const showResearchNotesGuide = ref(false);
    const showValuationTour = ref(false);
    const showNewHoldingVideo = ref(false);
    const showIMSTour = ref(false);

    const hash = window.location.hash;
    if(hash == '#valuationtour'){
        showValuationTour.value = true;
    }else if(hash == '#researchguide'){
        showResearchNotesGuide.value = true;
    }else if(hash == '#securemessagetour'){
        showSecureMessageTour.value = true;
    }else if(hash == '#imstour'){
        showIMSTour.value = true;
    }else if(hash == '#newholdingvideo'){
        showNewHoldingVideo.value = true;
    }else if(hash == '#welcometour'){
            store.dispatch('setActiveModal', {modal: 'WelcomeTour'})
    }

    const responsiveOptions = ref([
      {
        breakpoint: '1600px',
        numVisible: 3,
        numScroll: 1
      },
      {
        breakpoint: '1400px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '800px',
        numVisible: 1,
        numScroll: 1
      }
    ]);

    const cards = computed( () => {

        switch (props.mode) {
            case 'dashboard':
                return [
                    {
                        image: require("@/assets/img/helpCard/Sticky-Man-7.svg"),
                        title: "VIEW YOUR INVESTMENTS",
                        blurb: "A combined view of the data from your automated data feeds as well as any self-managed holdings you have added to the site.",
                        buttonLink: "/investments/valuation",
                        buttonText: "View Valuation"
                    },
                    {
                        image: require("@/assets/img/helpCard/Sticky-Family-1@2x.png"),
                        title: "GUIDED TOUR",
                        blurb: "Watch our Guided Tour video to become familiar with the clarity website.",
                        // buttonLink: "/investments/valuation",
                        buttonLink: "dashboardguidedtour",
                        buttonText: "Start the Tour",
                        action: {action: 'setActiveModal', payload: {modal: 'WelcomeTour'}}
                    },
                    {
                        image: require("@/assets/img/helpCard/Sticky-Couple-3.svg"),
                        title: "FUND PROVIDER DATA FEEDS",
                        blurb: "View or update your data feeds for your current clarity Investments.",
                        buttonLink: "/investments/datafeeds",
                        buttonText: "Update Feeds"
                    }
                ]
            case 'investments':
                return [
                    {
                        image: require("@/assets/img/helpCard/Sticky-Man-4.svg"),
                        title: "ONLINE VALUATION TOUR",
                        blurb: "Find out more about the features of our online valuation tool.",
                        //buttonLink: "/investments/valuation",
                        buttonLink: "valuationtour",
                        buttonText: "Start the Tour"
                    },
                    {
                        image: require("@/assets/img/helpCard/Sticky-Family-1@2x.png"),
                        title: "GUIDED TOUR",
                        blurb: "Watch our Guided Tour video to become familiar with the clarity website.",
                        // buttonLink: "/investments/valuation",
                        buttonLink: "dashboardguidedtour",
                        buttonText: "Start the Tour"
                    },
                    {
                        image: require("@/assets/img/helpCard/Sticky-Couple-3.svg"),
                        title: "FUND PROVIDER DATA FEEDS",
                        blurb: "View or update your data feeds for your current clarity Investments.",
                        buttonLink: "/investments/datafeeds",
                        buttonText: "Update Feeds"
                    }
                ]
            case 'messaging':
                return [
                    {
                        image: require("@/assets/img/helpCard/Sticky-Couple-2.svg"),
                        title: "SECURE MESSAGING TOUR",
                        blurb: "Discover how to open, reply, send and attach documents to Secure Messages.",
                        buttonLink: "securemessagingtour",
                        buttonText: "Start the Tour"
                    },
                    {
                        image: require("@/assets/img/helpCard/Sticky-Woman-1.svg"),
                        title: "YOUR SECURITY",
                        blurb: "Make sure your clarity account is as secure as possible by enabling our security features.",
                        buttonLink: "/settings/websettings",
                        buttonText: "Read more"
                    },
                    {
                        image: require("@/assets/img/helpCard/Sticky-Man-5.svg"),
                        title: "CONTACT CLARITY",
                        blurb: "Contact us by phone, email or Secure Message.",
                        buttonLink: "/securemessaging/message",
                        buttonText: "Contact clarity"
                    }
                ]
            case 'tools':
                return [
                    {
                        image: require("@/assets/img/helpCard/Sticky-Man-4.svg"),
                        title: "INTRODUCING WEALTHPLAN",
                        blurb: "A powerful financial planning tool, WealthPlan can help you take control of your money.",
                        buttonLink: "/tools/wealthplan",
                        buttonText: "Read more"
                    },
                    {
                        image: require("@/assets/img/helpCard/Sticky-Couple-1.svg"),
                        title: "CALCULATORS",
                        blurb: "Helping you ‘do the maths’ to achieve your financial goals and model different scenarios.",
                        buttonLink: "/tools/calculators",
                        buttonText: "Read more"
                    },
                    {
                        image: require("@/assets/img/helpCard/Sticky-Man-3.svg"),
                        title: "BUDGETING",
                        blurb: "Use our online tools to help you budget and plan your finances.",
                        buttonLink: "/tools/calculators",
                        buttonText: "Read more"
                    }
                ]
            case 'research':
                return [
                    {
                        image: require("@/assets/img/helpCard/Sticky-Man-2.svg"),
                        title: "RESEARCH TOUR",
                        blurb: "Find out more about the Research Library and the Investment Buy Notes.",
                        buttonLink: "researchnoteguide",
                        buttonText: "Start the Tour"
                    },
                    {
                        image: require("@/assets/img/helpCard/Sticky-Couple.svg"),
                        title: "ABOUT clarityCURRENCY",
                        blurb: "Make currency transfers with competitive exchange rates so your money goes further.",
                        buttonLink: process.env.VUE_APP_CLARITYURL+"/aboutourpartners#transfercurrency",
                        buttonText: "Transfer a Currency"
                    },
                    {
                        image: require("@/assets/img/helpCard/Sticky-Man-1.svg"),
                        title: "VIDEO & WEBINARS",
                        blurb: "Find our guided tours and webinars here.",
                        buttonLink: process.env.VUE_APP_CLARITYURL+"/toolsvideos",
                        buttonText: "Watch now"
                    }
                ]
            case 'info':
                return [
                    {
                        image: require("@/assets/img/helpCard/Sticky-Woman-1.svg"),
                        title: "ABOUT MULTI-FACTOR AUTHENTICATION",
                        blurb: "Make your clarity account more secure by enabling Multi-factor Authentication.",
                        buttonLink: "/settings/websettings#target-mfa",
                        buttonText: "Read More"
                    },
                    {
                        image: require("@/assets/img/helpCard/Sticky-Woman.svg"),
                        title: "COMPLETE THE RISK PROFILER",
                        blurb: "Determine the amount of risk you are willing to take with regard to your investments.",
                        buttonLink: "/settings/riskprofiler",
                        buttonText: "Read More"
                    },
                    {
                        image: require("@/assets/img/helpCard/Sticky-Man.svg"),
                        title: "CONTACT CLARITY",
                        blurb: "Contact us by phone, email or Secure Message.",
                        buttonLink: process.env.VUE_APP_CLARITYURL+"/homepage#clarity-contact-us-form",
                        buttonText: "Contact clarity"
                    }
                ]
            default:
                //default to investment.
                return [
                    {
                        image: require("@/assets/img/helpCard/Sticky-Man-4.svg"),
                        title: "ONLINE VALUATION TOUR",
                        blurb: "Find out more about the features of our online valuation tool.",
                        buttonLink: "/investments/valuation",
                        buttonText: "Start the Tour"
                    },
                    {
                        image: require("@/assets/img/helpCard/Sticky-Family-1@2x.png"),
                        title: "GUIDED TOUR",
                        blurb: "Watch our Guided Tour video to become familiar with the clarity website.",
                        // buttonLink: "/investments/valuation",
                        buttonLink: "dashboardguidedtour",
                        buttonText: "Start the Tour"
                    },
                    {
                        image: require("@/assets/img/helpCard/Sticky-Couple-3.svg"),
                        title: "FUND PROVIDER DATA FEEDS",
                        blurb: "View or update your data feeds for your current clarity Investments.",
                        buttonLink: "/investments/datafeeds",
                        buttonText: "Update Feeds"
                    }
                ]
        }

    });

    const openPage = (link) => {
      if(link.includes('http')) {
        window.open(link, '_blank');
      } else if(link.includes('securemessagingtour')) {
        loadSecureMessageTour();
      } else if(link.includes('researchnoteguide')){
        loadResearchNotesGuide();
      } else if(link.includes('dashboardguidedtour')) {
        loadDashboardWelcomeTour();
      } else if(link.includes('valuationtour')) {
          loadValuationTour();
      } else {
        router.push(link);
      }
    }

    const triggerAction = (actionObj) => {
      store.dispatch(actionObj.action, actionObj.payload)
    }

    const closeModal = () => {
      if(showSecureMessageTour.value) {
        showSecureMessageTour.value = false;
      }
      if(showResearchNotesGuide.value) {
        showResearchNotesGuide.value = false;
      }
      if(showValuationTour.value) {
        showValuationTour.value = false;
      }
      if(showNewHoldingVideo.value) {
          showNewHoldingVideo.value = false;
      }
      if(showIMSTour.value) {
          showIMSTour.value = false;
      }
    }

    const loadSecureMessageTour = () => {
      if(showSecureMessageTour.value) {
        showSecureMessageTour.value = false;
      } else {
        showSecureMessageTour.value = true;
      }
    }

    const loadResearchNotesGuide = () => {
      if(showResearchNotesGuide.value) {
        showResearchNotesGuide.value = false;
      } else {
        showResearchNotesGuide.value = true;
      }
    }

    const loadValuationTour = () => {
      if(showValuationTour.value) {
        showValuationTour.value = false;
      } else {
        showValuationTour.value = true;
      }
    }

    const loadDashboardWelcomeTour = () => {
      store.dispatch('setActiveModal', {modal: 'WelcomeTour'})
    }

    return {
      responsiveOptions,
      cards,
      openPage,
      showSecureMessageTour,
      showResearchNotesGuide,
      showValuationTour,
      showNewHoldingVideo,
      showIMSTour,
      triggerAction,
      closeModal
    }
  },
}
</script>

<style scoped>
.clarity-card-inner {
  border: none !important;
}

.help-inner-card {
  padding: 20px;
  min-height: 399px
}

.help-inner-card button {
  width: 200px;
}

.help-inner-card-content {
  height: 100px;
}

h3.center {
  text-align: center;
  min-height: 60px;
}

.body-text {
  text-align: center;
}

@media only screen and (max-width: 1366px) {
  .help-inner-card h3 {
    font-size: 18px;
  }

  .body-text {
    font-size: 16px !important;
  }

  .help-inner-card-content {
    height: 100px;
  }
}

@media only screen and (max-width: 900px) {

    .help-inner-card-content {
        height: 160px;
    }
}

@media only screen and (max-width: 768px) {
  .help-inner-card button {
    width: 200px;
  }
}

@media only screen and (max-width: 600px) {
  .help-inner-card button {
    width: 100% !important;
  }
}

@media only screen and (max-width: 400px) {
  .help-inner-card-content {
    height: 180px;
  }
}

.img-wrapper {
    /* UI Properties */
    width: 100%;
    height: 300px;
    border: 2px solid var(--clarity-pure-white);
    border-radius: 27px;
    opacity: 1;
    box-shadow: 0 5px 20px #0000000D;
    background: transparent linear-gradient(180deg, var(--clarity-light-blue) 0%, var(--clarity-blue) 100%) 0 0 no-repeat padding-box;
    /*background-image: url('../../assets/img/dashboard/Background.png');
    background-size: cover;*/
}
.img-wrapper.light {
    box-shadow: 0 5px 20px #0000000D;
    background: transparent linear-gradient(180deg, #7CC2E2 0%, var(--clarity-light-blue) 100%) 0 0 no-repeat padding-box;
}
.card-img {
    max-height: 75%;
    max-width: 70%;
}

/* MODAL CSS */
#sm-welcome-tour {
  /* needs to be high to sit above all other elements, including nav*/
  z-index: 3104;
}

#sm-welcome-tour:deep(.modal-body) {
  min-width: 65%;
}

#sm-welcome-tour:deep(.modal-close) {
  display: none !important;
}

#sm-welcome-tour:deep(.clarity-card-content) {
  width: unset !important;
}

#sm-welcome-tour:deep(.content-title) {
  padding: 30px;
}
#sm-welcome-tour:deep(.content-full) {
  padding: 0px 30px 30px 30px;
}
</style>
